* {
  font-family: 'Raleway', sans-serif;
}

.fade-hero {
  opacity: 0;
  transition: all 1s;
  transition-delay: 3800ms;
}

.fade-hero-after {
  transition-delay: 0ms;
}

.fade-hidden {
  opacity: 0;
  transform: translateY(50%);
  transition: all 1s;
}

.fade-show {
  opacity: 1;
  transform: translateX(0);
}

.fade-intro:nth-child(1) {
  transition-delay: 4600ms;
}

.fade-intro:nth-child(2) {
  transition-delay: 4700ms;
}

.fade-intro:nth-child(3) {
  transition-delay: 4800ms;
}

.fade-navbar:nth-child(1) {
  transition-delay: 4300ms;
}

.fade-navbar:nth-child(2) {
  transition-delay: 4400ms;
}

.fade-navbar:nth-child(3) {
  transition-delay: 4500ms;
}

.fade-navbar:nth-child(4) {
  transition-delay: 4600ms;
}

.fade-navbar:nth-child(5) {
  transition-delay: 4700ms;
}

.fade-navbar:nth-child(6) {
  transition-delay: 4800ms;
}

.fade-about:nth-child(1) {
  transition-delay: 100ms;
}

.fade-about:nth-child(2) {
  transition-delay: 200ms;
}

.fade-skills:nth-child(2) {
  transition-delay: 300ms;
}

.fade-skills:nth-child(3) {
  transition-delay: 400ms;
}

.fade-skills:nth-child(4) {
  transition-delay: 500ms;
}

.fade-skills:nth-child(5) {
  transition-delay: 600ms;
}

.fade-skills:nth-child(6) {
  transition-delay: 700ms;
}

.fade-skills:nth-child(7) {
  transition-delay: 800ms;
}

.fade-experience:nth-child(1) {
  transition-delay: 100ms;
}

.fade-experience:nth-child(2) {
  transition-delay: 100ms;
}

.fade-experience:nth-child(3) {
  transition-delay: 100ms;
}

.fade-experience:nth-child(4) {
  transition-delay: 100ms;
}

.fade-experience:nth-child(5) {
  transition-delay: 100ms;
}

.fade-experience:nth-child(5) {
  transition-delay: 100ms;
}

.fade-projects:nth-child(1) {
  transition-delay: 100ms;
}

.fade-projects:nth-child(2) {
  transition-delay: 0ms;
}

.fade-projects:nth-child(3) {
  transition-delay: 100ms;
}

.fade-projects:nth-child(4) {
  transition-delay: 200ms;
}

.fade-projects:nth-child(5) {
  transition-delay: 300ms;
}

.fade-connect:nth-child(1) {
  transition-delay: 100ms;
}

.fade-connect:nth-child(2) {
  transition-delay: 300ms;
}

.fade-connect:nth-child(3) {
  transition-delay: 400ms;
}

.fade-connect:nth-child(4) {
  transition-delay: 500ms;
}

@media screen and (max-width: 768px) {
  .fade-skills:nth-child(1) {
    transition-delay: 100ms;
  }

  .fade-skills:nth-child(2) {
    transition-delay: 100ms;
  }
  
  .fade-skills:nth-child(3) {
    transition-delay: 100ms;
  }
  
  .fade-skills:nth-child(4) {
    transition-delay: 100ms;
  }
  
  .fade-skills:nth-child(5) {
    transition-delay: 100ms;
  }
  
  .fade-skills:nth-child(6) {
    transition-delay: 100ms;
  }
  
  .fade-skills:nth-child(7) {
    transition-delay: 100ms;
  }
}
